import { FETCH_POSTS, FETCH_POSTS_FILTER } from "../actions/activity/type";

const initialState = {
   itemsPopular: [],
   items: [],
};

export default (state = initialState, action) => {
   switch (action.type) {
      case FETCH_POSTS:
         return {
            ...state,
            ...action,
            itemsPopular: action.items.popular,
         };
      case FETCH_POSTS_FILTER:
         return {
            ...state,
            ...action,
         };

      default:
         return state;
   }
};
