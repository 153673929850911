import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { FishCameraButton } from "../Atlantis/CodeSplitting";
import MainText from "../MainText/MainText";
import { showScanButton } from "../../actions/scanbutton/scanbutton";
import { replaceUrl } from "../../utils/Function";
import { useHistory } from "react-router-dom";
import "./JourneyBgImage.css";

const Journey = () => {
  const [loginData, setLoginData] = useState(localStorage.getItem('loginData'));
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (replaceUrl(location.pathname) === "journey") {
      dispatch(showScanButton(false));
    }
    return () => {
      dispatch(showScanButton(true));
    };
  }, []);

  window.onbeforeunload = function() {
    localStorage.clear();
 }
 

  return (
    <div className="journey-container">
      {/* <img src="images/avatar.png" alt="" id="greek" /> */}
      <div className="top-section">
        {
          !loginData ? <p className="top-text">Your presence is undetected: <span className="highlight">Lost Nemo</span></p> :
          <p className="top-text">You are logged in as: <span className="highlight">{loginData}</span></p>

        }
        <MainText>Start exploring the marine life</MainText >
        <p className="description" >
          Simply Press <span className="highlight">“Scan Fish”</span> to learn more
        </p>
        <div className="icons-section">
          <section>
            <div className="icon">
              <img src="images/focus.svg" alt="" />
            </div>
            Focus on the fish
          </section>
          <section>
            <div className="icon">
              <img src="images/camera_icon.svg" alt="" />
            </div>
            Turn off your camera flash
          </section>
        </div>
        <FishCameraButton />
      </div>

      <div className="middle-section">
        <span>OR</span>
      </div>

      <div className="journey-bottom">
        <h1 className="main-text">
          Want to discover <br /> more at your own pace?
        </h1>
        <p className="description">
          Simply Press <span className="highlight">“Atlantis Aquaventure”</span> to learn more
        </p>
        {/* <img className="btn-logo" src="images/logobutton.png" alt="" onClick={() => history.push("/home")} /> */}
        <div className="journey-logo" onClick={() => history.push("/home")} >
        <img className="btn-logo" src={require("../../public/images/journeylogo.png")} alt="journeylogo" />
        </div>
      </div>
    </div>
  );
};

export default Journey;
