const initialState = {
    items: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_FISH":
            return {
                ...state,
                ...action,
            };
        case "OPEN_CAMERA":
            return {
                ...state,
                ...action,
            };
        case "CLEAR_POSTS":
            return {
                ...state,
                ...action,
            };

        default:
            return state;
    }
};
