import { FETCH_POSTS } from "../actions/sharklostdolsea/type";

const initialState = {
    dolphin: [],
    sealion: [],
    lostchambers: [],
    shark: [],
    items: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSTS:
            return {
                ...state,
                dolphin: action.items.dolphin,
                lostchambers: action.items.chambers,
                sealion: action.items.sealion,
                shark: action.items.shark,
                items: action.items,
            };
        default:
            return state;
    }
};
