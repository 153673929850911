import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Description from "../../Description/Description";
import Button from "../../Button/Button";
import Clock from "../../../public/images/ic_clock.svg";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { get } from "lodash";
import { replaceUrl } from "../../../utils/Function";
const FootCarousel = props => {
   const history = useHistory();
   const location = useLocation();
   const style = {
      backgroundImage: "url(" + Clock + ")",
   };

   const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 300,
      edgeFriction: 0,
      slidesToShow: 1.3,
      slidesToScroll: 1,
      draggable: true,
      swipeToSlide: true,
      touchMove: true,
      responsive: [
         {
            breakpoint: 3000,
            settings: {
               slidesToShow: 5,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 1600,
            settings: {
               slidesToShow: 3.5,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 1030,
            settings: {
               slidesToShow: 2.8,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 835,
            settings: {
               slidesToShow: 2.5,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 634,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 563,
            settings: {
               slidesToShow: 1.8,
               slidesToScroll: 1,
            },
         },

         {
            breakpoint: 500,
            settings: {
               slidesToShow: 1.3,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 370,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };

   return (
      <div className="foot-carousel-container">
         {replaceUrl(location.pathname).includes("activity-details") ? (
            <Slider edgeFriction={0} {...settings}>
               <div className="parent">
                  <div className="time-container" style={style}>
                     {/* <Description className="time">{data.time.slice(0, 5)}</Description>
                        <Description className="ampm">{data.time.slice(-2)}</Description> */}

                     {get(props.media[0], "recurrence", "")}
                  </div>
                  <img className="frame" src={require("../../../public/images/foot.svg")} alt="" />
                  <img
                     className="images"
                     src={require("../../../public/images/ic_dolphin.svg")}
                     alt=""
                  />
                  <Description className="header">{get(props.media[0], "name", "")}</Description>
                  <Description className="desc">
                     {get(props.media[0], "desc", "").slice(0, 45) + "..."}
                  </Description>
                  <Button
                     onClick={() => history.push(`/activity-details/${get(props.media[0], "uid", "")}`)}
                     className="more-button"
                  >
                     Read More
                  </Button>
               </div>
               <div className="parent">
                  <div className="time-container" style={style}>
                     {/* <Description className="time">{data.time.slice(0, 5)}</Description>
                        <Description className="ampm">{data.time.slice(-2)}</Description> */}

                     {get(props.media[1], "recurrence", "")}
                  </div>
                  <img className="frame" src={require("../../../public/images/foot.svg")} alt="" />
                  <img
                     className="images"
                     src={require("../../../public/images/ic_dolphin.svg")}
                     alt=""
                  />
                  <Description className="header">{get(props.media[1], "name", "")}</Description>
                  <Description className="desc">
                     {get(props.media[1], "desc", "").slice(0, 45) + "..."}
                  </Description>
                  <Button
                     onClick={() => history.push(`/activity-details/${get(props.media[1], "uid", "")}`)}
                     className="more-button"
                  >
                     Read More
                  </Button>
               </div>
            </Slider>
         ) : props.media.length === 0 ? (
            <div className="parent placeholder">
               <img src={require("../../../public/images/foot.svg")} alt="" />
            </div>
         ) : (
            <Slider edgeFriction={0} {...settings}>
               {props.media.map((data, index) => (
                  <div className="parent" key={index}>
                     <div className="time-container" style={style}>
                        {/* <Description className="time">{data.time.slice(0, 5)}</Description>
                            <Description className="ampm">{data.time.slice(-2)}</Description> */}

                        {get(data, "recurrence", "")}
                     </div>
                     <img className="frame" src={require("../../../public/images/foot.svg")} alt="" />
                     <img
                        className="images"
                        src={require("../../../public/images/ic_dolphin.svg")}
                        alt=""
                     />
                     <Description className="header">{data.name}</Description>
                     <Description className="desc">{data.desc.slice(0, 45) + "..."}</Description>
                     <Button
                        onClick={() => history.push(`/activity-details/${data.uid}`)}
                        className="more-button"
                     >
                        Read More
                     </Button>
                  </div>
               ))}
            </Slider>
         )}
      </div>
   );
};

export default withRouter(FootCarousel);
