import { FETCH_POSTS, FETCH_POSTS_FILTER } from "./type";
import { axiosCustom } from "../../utils/Request";

export const fetchingActivity = () => async dispatch => {
   const response = await axiosCustom.get("experience/v1/getexperience");
   const { data = false, status } = response;
   if (status === 200) {
      if (data) {
         dispatch({
            type: FETCH_POSTS,
            items: data,
         });
      }
   }
};

// Filtered Data
export const fetchingFilteredData = selectedFilter => async dispatch => {
   const response = await axiosCustom("filter/v1/managefilter", {
      method: "POST",
      data: selectedFilter,
   });
   const { data = false, status } = response;
   if (status === 200) {
      if (data) {
         dispatch({
            type: FETCH_POSTS_FILTER,
            items: data,
         });
      }
   }
};
