import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import MainText from "../MainText/MainText";
import { showScanButton } from "../../actions/scanbutton/scanbutton";
import { replaceUrl } from "../../utils/Function";
import { useHistory } from "react-router-dom";
// import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { gapi } from "gapi-script";
import './SocialLoginBgImage.css'
import { Cookies } from 'react-cookie';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'


const SocialPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const InputEmail = null;

  window.onbeforeunload = function () {
    localStorage.clear();
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: '537814903869-9757hv853419majdci375ackf6j9td6d.apps.googleusercontent.com',
        scope: 'email',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  const responseFacebook = (response) => {
    localStorage.setItem('loginData', response.email);
    history.push('/journey')
    console.log(response);
    const cookies = new Cookies();
    let facebookData = {
      token: response.accessToken,
      name: response.name,
      email: response.email
    }
    // const facebookObj = '?' + new URLSearchParams(facebookData).toString();
    // console.log(facebookObj, "facebookObj"); 
    // console.log(facebookData.token);
    // console.log(facebookData.email)
    // console.log(facebookData.name)

    cookies.set('user_cookie', facebookData, {
      path: '/',
      maxAge: 432000,
    });

    //   cookies.set('facebook', [response.accessToken,response.name, response.email],{
    //     path: '/',
    //     maxAge: 432000,
    //   }); 
  }

  const handleLogin = (response) => {
    localStorage.setItem('loginData', response.profileObj.email);
    history.push('/journey')
    console.log(response)
    const cookies = new Cookies()
    let googleData = {
      token: response.tokenObj.access_token,
      name: response.profileObj.name,
      email: response.profileObj.email
    }
    cookies.set('user_cookie', googleData, {
      path: '/',
      maxAge: 432000,
    });
  }

  useEffect(() => {
    if (replaceUrl(location.pathname) === "socialpage") {
      dispatch(showScanButton(false));
    }
    return () => {
      dispatch(showScanButton(true));
    };
  }, []);


  const handleChange = event => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('loginData', email);
    history.push('/journey')
    const unique_id = Math.random().toString(36).substring(2,35);
    
    console.log(unique_id)
    const cookies = new Cookies()
    const userEmail = {
      email: email,
      token:unique_id,
    }
    cookies.set('user_cookie', userEmail, {
      path: '/',
      maxAge: 432000,
    });
 
  }

  const handleSkip = (event) => {
    event.preventDefault();
    history.push('/journey')
    const unique_id = Math.random().toString(36).substring(2,35);
    console.log(unique_id)
    const cookies = new Cookies()
    localStorage.setItem('tokenId', unique_id);
    const userToken = {
      token:unique_id,
    }
    cookies.set('user_cookie', userToken, {
      path: '/',
      maxAge: 432000,
    });
  }


  return (
    <div className="socialLogin-container">
      <div className="top-section">
        <MainText>Sign In To Enhance Your Experience</MainText>
        <div className="top-section">
          <section>
            <div className="social-btn">
              <img src={require("../../public/images/Facebook-2.svg")} className="social-icon" alt="" />
              <FacebookLogin
              render={renderProps => (
                <button onClick={renderProps.onClick} className="facebook-btn">Sign in with Facebook</button>
              )}
                appId="549942773712230"
                fields="name,email"
                callback={responseFacebook}
                onFailure={err => console.log('fail', err)} isSignedIn={true}
              />
            </div>
          </section>
          <section>
            <div className="social-btn">
              <img src={require("../../public/images/google.svg")} alt="" className="google-icon" />
              <GoogleLogin
                render={(renderProps) => (
                  <button onClick={renderProps.onClick} className="google-btn" disabled={renderProps.disabled}>
                    Sign in with Google
                  </button>
                )}

                clientId="537814903869-9757hv853419majdci375ackf6j9td6d.apps.googleusercontent.com"
                className="google-btn"
                onSuccess={handleLogin}
                redirectUri="https://atlantis.bluelogic.ai/auth/google/callback"
                onFailure={err => console.log('fail', err)} isSignedIn={true}
                cookiePolicy={'single_host_origin'}
              />
            </div>
          </section>
          {/* <p className="description">
            By continuing, you agree with our <span className="highlight"><br />terms & conditions.</span>
          </p> */}
        </div>

      </div>

      <div className="middle-section">
        <span>OR</span>
      </div>

      <div className="bottom-section">
        <div className="bottom-btn">
          <form >
              <input className="email-input" type="email"
               placeholder="Enter your email address" name="email" id="email" 
               value={email} onChange={handleChange} />
              {error && <h2 style={{ color: '#ccc', fontSize: "16px" }}>{error}</h2>}
              <button className="submit-btn" type="submit" onClick={handleSubmit} disabled={!email}>Submit</button>
          </form>

        </div>
        <span onClick={handleSkip}>Skip <img src={require("../../public/images/chevron-left.svg")} alt="" /></span>
      </div>
    </div>
  );
};

export default SocialPage;
