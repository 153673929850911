import React from "react";

const Hamburger = () => {
   const toggleMainMenu = () => {
      // Animation to slide the Main Menun
      const MainMenu = document.querySelector("#mainMenu");
      MainMenu.style.left = "0";

      // Animation to shrink the Content Section
      const rootContainer = document.querySelector(".root-container");
      // console.log(rootContainer);
      rootContainer.classList.add("shrinkAnimation");
      const shrinkedContainerClick = document.querySelector(".overlay-wrapper");
      shrinkedContainerClick.style.display = "block";
   };
   return (
      <img
         className="icon"
         src={require("../../public/images/hamburger.svg")}
         onClick={toggleMainMenu}
         alt=""   
      />
   );
};

export default Hamburger;
