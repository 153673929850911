import {
    FETCH_POSTS_DOLPHIN_SEALION,
    CLEAR_POST_DOLPHIN_SEALION,
} from "../actions/dolphinandsealionbay/type";

const initialState = {
    items: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSTS_DOLPHIN_SEALION:
            return {
                ...state,
                ...action,
            };
        case CLEAR_POST_DOLPHIN_SEALION:
            return {
                items: [],
            };
        default:
            return state;
    }
};
