import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import MainText from "../MainText/MainText";

const Audio = ({ audioItems }) => {
   return (
      <div className="audio-section listen-section">
         <MainText>Tune In</MainText>
         <AudioPlayer src={audioItems} autoPlayAfterSrcChange={false} controls={false} />
      </div>
   );
};

export default Audio;
