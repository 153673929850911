import React from "react";
import { Link } from "react-router-dom";
import MainText from "../MainText/MainText";

const TextAndButton = props => {
   return (
      <div className="text-button-container">
         <MainText>{props.header}</MainText>
         <Link to={`/${props.link}`}>
            {props.text ? props.text : "View all"}{" "}
            <img
               className="image pink"
               src={require("../../public/images/ic_arrow-right-white.svg")}
               alt=""
            />
         </Link>
      </div>
   );
};

export default TextAndButton;
