import React from "react";
import Description from "../Description/Description";
import Bgc from "../../public/images/bigReactangle2.svg";
import { withRouter } from "react-router-dom";

const IconsSkew = () => {
  const style = { backgroundImage: "url(" + Bgc + ")" };

  return (
    <div className="icons-skew-container" style={style}>
      <Description className="description-black-14">
        Once you scan a fish, make sure you check out its status to see if it is endangered in the wild.
      </Description>
      <img src="images/conservation.png" className="timeline" loading="lazy" alt="" />
      {/* {props.location.pathname.includes("lost") && (
                <Description className="description-black-14 bottom-p">
                    You can also see these near the displays.
                </Description>
            )} */}
    </div>
  );
};

export default withRouter(IconsSkew);
