import { combineReducers } from "redux";
import careandconservation from "./conservation";
import animalsandchambers from "./animalsandchambers";
import animalchamberlist from "./animalchamberlist";
import venues from "./venues";
import lostchambers from "./lostchambers";
import dolphinandsealionbay from "./dolphinandsealionbay";
import home from "./home";
import fishdetails from "./fishdetails";
import fishupload from "./fishupload";
import sharklostdolsea from "./sharklostdolsea";
import sharklagoon from "./sharklagoon";
import help from "./help";
import activity from "./activity";
import showbutton from "./showbutton";
import expertlist from "./expertlist";
import footer from "./footer";

export default combineReducers({
   careandconservation,
   animalsandchambers,
   animalchamberlist,
   venues,
   lostchambers,
   dolphinandsealionbay,
   home,
   help,
   fishdetails,
   fishupload,
   sharklostdolsea,
   sharklagoon,
   activity,
   showbutton,
   expertlist,
   footer
});
