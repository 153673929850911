import {
   FETCH_POSTS_ANIMAL,
   FETCH_POSTS_CHAMBER,
   FETCH_POSTS_CHAMBER_DETAILS,
   FETCH_POSTS_ANIMAL_DETAILS,
   FETCH_POSTS_ANIMAL_DETAILS_PAGINATION,
} from "../actions/animalchamberlist/type";

const initialState = {
   itemsAnimals: [],
   itemsChambers: [],
   itemsChambersDetials: [],
   itemsAnimalsDetails: [],
};

export default (state = initialState, action) => {
   switch (action.type) {
      case FETCH_POSTS_ANIMAL:
         return {
            ...state,
            itemsAnimals: action.items,
         };
      case FETCH_POSTS_CHAMBER:
         return {
            ...state,
            itemsChambers: action.items,
         };
      case FETCH_POSTS_CHAMBER_DETAILS:
         return {
            ...state,
            itemsChambersDetials: action.items,
         };
      case FETCH_POSTS_ANIMAL_DETAILS:
         return {
            ...state,
            itemsAnimalsDetails: action.items,
         };
      case FETCH_POSTS_ANIMAL_DETAILS_PAGINATION:
         /**
          * Looping through the inital animals and adding new set of items to the carousel
          */

         state.itemsAnimalsDetails[0].animals.push(...action.items);

         // const paginatedItems = [];
         // const items = state.itemsAnimalsDetails;
         // for (let i = 0; i < items.length; i++) {
         //    items[i].animals.push(...action.items);
         // }

         return {
            ...state,
            itemsAnimalsDetails: state.itemsAnimalsDetails,
         };
      default:
         return state;
   }
};
