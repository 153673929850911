import React from "react";
import { useHistory } from "react-router-dom";
import Description from "../../Description/Description";
import Button from "../../Button/Button";

const ErrorMessageFish = () => {
   const history = useHistory();

   return (
      <div className="error-message-fish-container">
         <div className="header-section">
            <Description className="description-18">Details unavailable</Description>
            <img
               src={require("../../../public/images/close.svg")}
               onClick={() => history.push("/home")}
               alt=""
            />
         </div>

         <div className="section">
            <img src={require("../../../public/images/bigfish.PNG")} alt="" />

            <Description className="description-14">
               We currently do not have details about this fish. Please read about other fish in this
               exhibit.
            </Description>

            <Button className="normal-button" onClick={() => history.push(`/animals`)}>
               Take me to other fish
            </Button>
         </div>
      </div>
   );
};

export default ErrorMessageFish;
