/* eslint-disable */
import React, { useEffect, Suspense, Fragment, useState } from "react";
import Description from "../../Description/Description";
import Button from "../../Button/Button";

import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchingLostChambers } from "../../../actions/lostchambers";
import { fetchSharkLostDolSea } from "../../../actions/sharklostdolsea";
import MainText from "../../MainText/MainText";
import TextAndButton from "../../TextAndButton/TextAndButton";
// import CardCarousel from "../../Carousels/CardCarousel/CardCarousel";
import { textAllHeader, timeDelayWalkThrough } from "../../../config";
import IconsSkew from "../../IconsSkew/IconsSkew";
import Loader from "../../Loader/Loader";
import Hamburger from "../../Hamburger/Hamburger";
import Audio from "../../Audio/Audio";
// import WalkThrough from "../../WalkThrough/WalkThrough";
// import { closeToolTipButton } from "../../../utils/Function";
import LostDolSeaPlaceholder from "../../LostDolSeaPlaceholder/LostDolSeaPlaceholder";

// Code Splitting
const NumberCard = React.lazy(() => import("../../NumberCard/NumberCard"));
const SmallSkewCarousel = React.lazy(() =>
   import("../../Carousels/SmallSkewCarousel/SmallSkewCarousel")
);
const LearnAboutFish = React.lazy(() => import("../../LearnAboutFish/LearnAboutFish"));

const LostChamber = props => {
   // const [showPopupTooltip, setShowPopupTooltip] = useState(false);
   const items = useSelector(state => state.lostchambers.items);
   const lostChambersItems = useSelector(state => get(state.sharklostdolsea, "lostchambers", []));
   const dispatch = useDispatch();

   // Heading
   const heading = get(items, "section1.heading.english", "The Lost Chambers Aquarium");
   // Section One
   const sectionOneMedia = get(items, "section1.media", "");
   const sectionOneDescription = get(items, "section1.textall.english", "");
   // Section Two
   const sectionTwoHeader = get(items, `section2.${textAllHeader}`, "");
   const sectionTwoDescription = get(items, `section2.textall.Description.english`, "");
   const sectionTwoPairs = get(items, "section2.pairs", []);
   // Section Three
   // const sectionThreeHeader = get(items, `section3.${textAllHeader}`, "");
   // const sectionThreeDescription = get(items, `section3.textall.Description.english`, "");
   // const sectionThreeMedia = get(items, "section3.medias", []);
   // Section Four
   const sectionFourDescription = get(items, `section4.textall.english`, "");

   // const sectionFive = get(items, "section5[0]", []);

   useEffect(() => {
      if (items.length === 0) {
         dispatch(fetchingLostChambers());
      }
      if (lostChambersItems.length === 0) {
         dispatch(fetchSharkLostDolSea());
      }
   }, []);

   // useEffect(() => {
   //    let interval;
   //    if (
   //       localStorage.getItem("landingShow") === "false" &&
   //       localStorage.getItem("lostToolTip") === null
   //    ) {
   //       interval = setTimeout(() => {
   //          window.scrollTo(0, window.innerHeight < 700 ? 300 : window.innerWidth > 1000 ? 0 : 130);
   //          setTimeout(() => {
   //             setShowPopupTooltip(true);
   //             const audio_section = document.querySelector(".audio-section");
   //             const parent_top = audio_section.getBoundingClientRect().top;
   //             const parent_left = audio_section.getBoundingClientRect().left;

   //             audio_section.classList.add("temp-class");

   //             const walkthrough = document.querySelector(".animaldetails-page");
   //             walkthrough.style.top = parent_top - 370 + "px";
   //             walkthrough.style.left = parent_left + "px";
   //          }, 1000);
   //       }, timeDelayWalkThrough);
   //    }
   //    return () => {
   //       clearTimeout(interval);
   //    };
   // }, [localStorage.getItem("landingShow")]);

   // const togglePopup = bool => {
   //    setShowPopupTooltip(bool);
   //    const audio_section = document.querySelector(".audio-section");
   //    audio_section.classList.remove("temp-class");

   //    if (!bool) {
   //       closeToolTipButton("lostToolTip");
   //    }
   // };

   return (
      <div className="lost-chambers-container">
         <div className="header-section">
            <Hamburger />
            <Description className="description-18">{heading}</Description>
         </div>

         <div className="desktop-section-1">
            {sectionOneMedia === "" ? (
               <LostDolSeaPlaceholder />
            ) : (
               <div className="image-section">
                  <img src={sectionOneMedia} alt="" />
               </div>
            )}

            <div className="section section-one">
               <Description className="description-18">{heading}</Description>
               <Description className="description-14">{sectionOneDescription}</Description>

               <Audio audioItems={get(items, "audio")} />
            </div>
         </div>

         <div className="section-two">
            <div className="text-section">
               <MainText>{sectionTwoHeader}</MainText>
               <Description className="description-12">{sectionTwoDescription}</Description>
            </div>
            <Suspense fallback={<Loader />}>
               <NumberCard items={sectionTwoPairs} onClick={() => props.history.push("/animals")} />
            </Suspense>
         </div>

         <Suspense fallback={<Loader />}>
            <div className="section section-four">
               <LearnAboutFish description={sectionFourDescription} />
            </div>
         </Suspense>
         <Button className="normal-button" onClick={() => props.history.push("/chambers")}>
            Explore all the chambers
         </Button>

         <div className="section-eight">
            <div className="text-section">
               <MainText className="main-text">Know if your favourite fish is going extinct?</MainText>
            </div>
         </div>
         <IconsSkew />

         {/* <div className="section section-three">
            <TextAndButton header={sectionThreeHeader} link="chambers" />

            <CardCarousel
               videos={sectionThreeMedia}
               description={sectionThreeDescription}
               buttonText="How to reach"
               className="read-button"
               onClick={() => props.history.push("/map")}
            />
         </div> */}
         {/* <div className="section section-five">
            <div className="desktop-left-section">
               <div className="image">
                  <img src={get(sectionFive, "image", "")} alt="" />
               </div>
            </div>
            <div className="desktop-right-section">
               <div className="top-section">
                  <div className="image">
                     <img src={get(sectionFive, "image", "")} alt="" />
                  </div>
                  <div className="details">
                     <MainText className="main-text-black-16">{get(sectionFive, "name", "")}</MainText>
                     <p className="description-black-12">{get(sectionFive, "recurrence", "")}</p>
                     <p className="description-black-12">Age: {get(sectionFive, "age")}</p>
                     <p className="description-black-12">
                        Swimming Level:&nbsp;
                        <span className="text-lowercase">{get(sectionFive, "train", "")}</span>
                     </p>
                  </div>
               </div>
               <div className="bottom-section">
                  <Description className="description-black-12">
                     {get(sectionFive, "desc", "")}
                  </Description>
                  <Button
                     onClick={() => props.history.push(`/activity-details/${sectionFive.uid}`)}
                     className="more-button margin-top"
                  >
                     Read More
                  </Button>
               </div>
            </div>
         </div> */}

         {/* {showPopupTooltip && (
            <Fragment>
               <WalkThrough
                  image="images/ic_headphones.svg"
                  header="Press Play"
                  desc="Listen to what our experts say 
                  about our amazing marine 
                  animals and myth of Atlantis."
                  showPopup={showPopupTooltip}
                  // desc="Lean how we take care of our animals and our initiatives"
                  togglePopup={togglePopup}
                  line="images/animal_line.svg"
                  line_class="animaldetails-page"
                  align={41}
                  section_class="animaldetails-page"
                  subractLeft={0}
               />
               {/* <div className="mixblend">
            <p
              className="left-icon"
              onClick={() => showStatus(true)}
              style={{
                backgroundColor: "rgb(39, 100, 100)",
              }}
            >
              <img
                src={require("../../../public/images/new_info.svg")}
                alt=""
              />
              NT - Near Threatened
            </p>
          </div> */}
         {/* </Fragment> */}
         {/* )} */}

         <Suspense fallback={<Loader />}>
            <div className="section-six">
               <div className="text-section">
                  <TextAndButton header={lostChambersItems.heading} link="expertlist" text="Meet all" />
                  <Description className="description-12">{lostChambersItems.description}</Description>
               </div>
               <SmallSkewCarousel images={lostChambersItems.expert} />
            </div>
         </Suspense>
      </div>
   );
};

export default React.memo(LostChamber);
