// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import rootReducers from "./reducers";

// const initialState = {};

// const middleware = [thunk];

// const store = createStore(rootReducers, initialState, applyMiddleware(...middleware));

// export default store;


import {createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./reducers";

const initialState = {};

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducers, initialState, composeEnhancers(applyMiddleware(...middleware)));

export default store;
