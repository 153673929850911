import React from "react";
import Description from "../../Description/Description";
import Button from "../../Button/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get } from "lodash";
import PlayButton from "../../PlayButton/PlayButton";

export default props => {
   const videoRef = React.createRef();

   /**
    * Checking if it is and array or not, If Array then:
    *
    * Display Video
    *
    * otherwise
    *
    * Display Image
    *
    */

   const loadCarousel = () => {
      if (props.videos !== false) {
         return props.videos.map((data, index) => {
            if (data.media && data.media !== false) {
               if (data.media.includes("mp4") && data.check === true) {
                  return (
                     <div key={index} className="parent-carousel">
                        <video
                           className="video"
                           ref={videoRef}
                           src={`${get(data, "media", "")}#t=3.0`}
                           type="video/mp4"
                        />
                        <PlayButton videoRef={videoRef} />
                     </div>
                  );
               } else if (!data.media.includes("mp4") && data.check === true) {
                  return (
                     <div key={index}>
                        <img className="image" src={get(data, "media", "")} alt="" />
                     </div>
                  );
               } else {
                  return false;
               }
            }
            return false;
         });
      }
   };

   const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 300,
      edgeFriction: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      swipeToSlide: true,
      touchMove: true,
   };

   return (
      <div className="card-carousel-container">
         <div className="custom-slider">
            <Slider edgeFriction={0} {...settings}>
               {loadCarousel()}
            </Slider>
         </div>
         <div className="text-section">
            <Description className="description">{get(props, "description", "")}</Description>

            {props.buttonText && (
               <Button onClick={props.onClick} className={props.className}>
                  {get(props, "buttonText")}
               </Button>
            )}
         </div>
      </div>
   );
};
