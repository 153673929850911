import React from "react";
import Atlantis from "./components/Atlantis/Atlantis";
// import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Atlantis />
    </Router>
  );
};

export default App;
