import axios from "axios";
import { Environment } from "../env";

const Stagging = Environment.api_url + "/atlantis/index.php/wp-json/acf/v3/posts/";

const customStagging = Environment.api_url + "/atlantis/index.php/wp-json/";

export const axiosCustom = axios.create({
  baseURL: customStagging,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axios.create({
  baseURL: Stagging,
  headers: {
    "Content-Type": "application/json",
  },
});
