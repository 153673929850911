import { FETCH_POSTS } from "../actions/venues/type";

const initialState = {
    items: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSTS:
            return {
                ...state,
                ...action,
            };
        default:
            return state;
    }
};


// const initialLoggedInState = localStorage.getItem('isLoggedIn') ?? false;
// console.log("kalai", localStorage.getItem('isLoggedIn'));
// const [isLoggedIn, setIsLoggedIn] = useState(initialLoggedInState === 'true');

// useEffect(() => {
//   localStorage.setItem('isLoggedIn', isLoggedIn);
// }, [isLoggedIn])