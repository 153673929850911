import React, { useState } from "react";

const PlayButton = ({ videoRef }) => {
   const [isPlaying, setisPlaying] = useState(false);
   const [clientXonMouseDown, setclientXonMouseDown] = useState(null);
   const [clientYonMouseDown, setclientYonMouseDown] = useState(null);
   /**
    * Storing the Mouse Position on MousePress
    *
    * @param {*} event
    */
   const handleOnMouseDown = event => {
      setclientXonMouseDown(event.clientX);
      setclientYonMouseDown(event.clientY);

      event.preventDefault(); // stops weird link dragging effect
   };

   /**
    * Checking if the Mouse is Dragged or Clicked
    *
    * If Dragged then the click event will not be triggered
    *
    * @param {*} event
    * @param {*} payload
    * @param {*} title
    *
    */
   const handleOnClick = (event, data) => {
      event.stopPropagation();
      if (clientXonMouseDown !== event.clientX || clientYonMouseDown !== event.clientY) {
         event.preventDefault();
      } else {
         if (data === true) {
            videoRef.current.pause();
            setisPlaying(false);
         } else {
            videoRef.current.play();
            setisPlaying(true);
         }
      }
   };

   return (
      <div
         className={`play-button-container ${!isPlaying ? "show" : ""} `}
         onMouseDown={handleOnMouseDown}
         onClick={event => handleOnClick(event, isPlaying)}
      >
         {isPlaying === false ? (
            <img className="play-button" src={require("../../public/images/play_button.svg")} alt="" />
         ) : (
            <img className="pause-button" src={require("../../public/images/ic_pause.svg")} alt="" />
         )}
      </div>
   );
};

export default PlayButton;
