import { lazy } from "react";
// Code Splitting
export const LandingScreen = lazy(() =>
  import("../Pages/LandingScreen/LandingScreen")
);
export const Footer = lazy(() => import("../Footer/Footer"));
export const MainMenu = lazy(() => import("../MainMenu/MainMenu"));
export const FishCameraButton = lazy(() =>
  import("../FishCameraButton/FishCameraButton")
);
export const Header = lazy(() => import("../Header/Header"));
export const CareAndConservation = lazy(() =>
  import("../Pages/CareAndConservation/CareAndConservation")
);

export const PrivacyPolicy = lazy(() =>
  import("../Pages/PrivacyPolicy/PrivacyPolicy")
);

export const WebsiteTerms = lazy(() =>
  import("../Pages/WebsiteTerms/WebsiteTerms")
);

export const DataDeletionInstructions = lazy(() =>
  import("../Pages/DataDeletionInstructions/DataDeletionInstructions")
);


export const AnimalsAndChambers = lazy(() =>
  import("../Pages/AnimalsAndChambers/AnimalsAndChambers")
);
export const AnimalChamberList = lazy(() =>
  import("../Pages/AnimalsAndChambers/AnimalChamberList/AnimalChamberList")
);
export const AnimalsDetails = lazy(() =>
  import("../Pages/AnimalsAndChambers/AnimalsDetails/AnimalsDetails")
);
export const Venues = lazy(() => import("../Pages/Venues/Venues"));
export const ChambersDetails = lazy(() =>
  import("../Pages/AnimalsAndChambers/ChambersDetails/ChambersDetails")
);
// export const LostChambers = lazy(() => import("../Pages/LostChambers/LostChambers"));
export const DolphinAndSeaLion = lazy(() =>
  import("../Pages/DolphinAndSeaLion/DolphinAndSeaLion")
);
export const DolphinSealionDetails = lazy(() =>
  import(
    "../Pages/DolphinAndSeaLion/DolphinSealionDetails/DolphinSealionDetails"
  )
);
export const FishDetection = lazy(() =>
  import("../FishDetection/FishDetection")
);
export const Home = lazy(() => import("../Pages/Home/Home"));
export const Experts = lazy(() => import("../Pages/Experts/Experts"));
export const Help = lazy(() => import("../Pages/Help/Help"));
export const GroupBooking = lazy(() =>
  import("../Pages/GroupBooking/GroupBooking")
);
export const SharkLagoon = lazy(() =>
  import("../Pages/SharkLagoon/SharkLagoon")
);
export const ActivityList = lazy(() =>
  import("../Pages/Activity/ActivityList/ActivityList")
);
export const Map = lazy(() => import("../Pages/Map/Map"));
export const ExpertList = lazy(() => import("../Pages/ExpertList/ExpertList"));
export const AnimalListFromQR = lazy(() =>
  import("../Pages/AnimalListFromQR/AnimalListFromQR")
);
export const AnimalListFromQRWindow = lazy(() =>
  import("../Pages/AnimalListFromQRWindow/AnimalListFromQRWindow")
);






