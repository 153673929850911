import React, { Suspense, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import ScrollTop from "../ScrollTop/ScrollTop";
import Loader from "../Loader/Loader";
import {
  Footer,
  MainMenu,
  FishCameraButton,
  Header,
  CareAndConservation,
  AnimalsAndChambers,
  AnimalChamberList,
  AnimalsDetails,
  Venues,
  ChambersDetails,
  DolphinAndSeaLion,
  DolphinSealionDetails,
  FishDetection,
  Home,
  Experts,
  Help,
  GroupBooking,
  SharkLagoon,
  ActivityList,
  Map,
  ExpertList,
  AnimalListFromQR,
  AnimalListFromQRWindow,
  PrivacyPolicy,
  WebsiteTerms,
  DataDeletionInstructions
} from "./CodeSplitting";

import LostChambers from "../Pages/LostChambers/LostChambers";
import ActivityDetials from "../Pages/Activity/ActivityDetails/ActivityDetails";
import { useSelector } from "react-redux";
import ErrorMessageFish from "../FishDetection/ErrorMessage/ErrorMessageFish";
import Journey from "../Journey/Journey";
import SocialPage from "../SocialPage/SocialLogin";
// import LandingScreen from "../Pages/LandingScreen/LandingScreen";
// import { closeToolTipButton } from "../../utils/Function";

const Atlantis = (props) => {
  // const [showLandingScreen, setShowLandingScreen] = useState(localStorage.getItem("landingShow"));
  const showButton = useSelector((state) => state.showbutton.showButton);
  const detectMobile = /iPhone|iPad|iPod|Android/i;
  const isMobileDetected = detectMobile.test(navigator.userAgent);
  const location = useLocation();
  const history = useHistory();
  // useEffect(() => {
  //    if (localStorage.getItem("landingShow") === null) {
  //       localStorage.setItem("landingShow", true);
  //    }
  // }, []);

  // const closeLandingScreen = () => {
  //    setShowLandingScreen(false);
  //    closeToolTipButton("landingShow");
  // };



  useEffect(() => {
    if (!isMobileDetected) {
      if (window.location.hash == '#/websiteterms') {
        history.push("/websiteterms");
      }
      else if (window.location.hash == '#/privacypolicy') {
        history.push("/privacypolicy");
      }
      else if (window.location.hash == '#/datadeletioninstructions') {
        history.push("/datadeletioninstructions");
      }
      else{
        history.push("/home");
      }
    }
    
  }, []);



  return (
    <Switch>
      <ScrollTop>
        <Suspense fallback={<Loader />}>
          <MainMenu />
          {/* {location.pathname !== "/" && <Header />} */}
          {location.pathname !== "/" && location.pathname !== '/socialpage' && location.pathname !== '/journey' && <Header />}
        </Suspense>
        {/* {showLandingScreen === "true" && <LandingScreen closeLandingScreen={closeLandingScreen} />} */}
        <div className="root-container">
          <div className="overlay-wrapper"></div>
          <Suspense fallback={<Loader />}>
            <Route
              path={isMobileDetected ? ["/", "/journey"] : "/journey"}
              exact={isMobileDetected ? true : false}
              component={Journey}
            />
            <Route path="/socialpage" component={SocialPage} />
            <Route path="/lostchambers" component={LostChambers} />
            {/* <Route path="/booking" component={GroupBooking} /> */}
            <Route path="/home" component={Home} />
            <Route
              path="/careandconservation"
              component={CareAndConservation}
            />


            {/* Animals and Chambers  */}
            <Route path="/animalsandchambers" component={AnimalsAndChambers} />
            <Route path="/animals" component={AnimalChamberList} />
            <Route path="/chambers" component={AnimalChamberList} />
            <Route path="/animaldetails/:id" exact component={AnimalsDetails} />
            <Route
              path="/animaldetails/:name/:id/:category"
              component={AnimalsDetails}
            />
            <Route path="/error-message/:id" component={ErrorMessageFish} />
            <Route path="/chambersdetails/:id" component={ChambersDetails} />

            {/* Dolphin and Sealion And Shark  */}
            <Route path="/dolphin" component={DolphinAndSeaLion} />
            <Route path="/sealion" component={DolphinAndSeaLion} />
            <Route
              path="/dolphin-details/:id"
              component={DolphinSealionDetails}
            />
            <Route
              path="/sealion-details/:id"
              component={DolphinSealionDetails}
            />
            <Route path="/shark" component={SharkLagoon} />

            <Route path="/experts/:id" component={Experts} />
            <Route path="/expertlist" component={ExpertList} />

            <Route exact path="/activity-list" component={ActivityList} />
            <Route path="/activity-list/:id" component={ActivityList} />
            <Route path="/activity-details/:id" component={ActivityDetials} />

            <Route path="/map" component={Map} />
            <Route path="/animalListQR/:id" component={AnimalListFromQR} />
            <Route
              path="/animalListQRWindow/:id"
              component={AnimalListFromQRWindow}
            />
            <Route path="/venues" component={Venues} replace />
            <Route path="/help" component={Help} />
            <Route path="/camera" component={FishDetection} />


            {/* Privacy policy and website terms */}
            <Route
              path="/privacypolicy"
              component={PrivacyPolicy}
            />
            <Route
              path="/websiteterms"
              component={WebsiteTerms}
            />
            <Route
              path="/datadeletioninstructions"
              component={DataDeletionInstructions}
            />


          </Suspense>
        </div>
        <Suspense fallback={<Loader />}>
          {/* {showButton &&
            detectMobile.test(navigator.userAgent) &&
            location.pathname !== "/" && <FishCameraButton />} */}
          {location.pathname !== "/" && location.pathname !== '/socialpage' && location.pathname !== '/journey' && <Footer />}
          {/* {location.pathname !== "/" && <Footer />} */}
        </Suspense>
      </ScrollTop>
    </Switch>
  );
};

export default Atlantis;
