// Language
export const dynamicLanguage = "english";
// Heading
export const textAllHeader = `textall.heading.${dynamicLanguage}`;
// Description
export const textAllDescripiton = `textall.description.${dynamicLanguage}`;
// Medias
export const medias = "medias";
// Paris
export const pairs = "pairs";
// Steps
export const steps = "steps";

const timeDelayInSeconds = 5;
export const timeDelayWalkThrough = 1000 * timeDelayInSeconds;
