import { useLayoutEffect } from "react";
/**
 * Removes the / from the URL or from the given data
 *
 * @param {*} data
 */
export const replaceUrl = data => data.replace("/", "");

export const generateToken = () => {
   const a = Date.now();
   const b = "SecretKey";
   const c = Math.random();
   const z = a + b + c;

   return z;
};

/**
 *
 * Generic Function to resolve reducer
 *
 * @param {string} state
 * @param {string} action
 */
export const resolve_redux_state = (state = null, action = null) => {
   if (action && state) {
      return {
         ...state,
         ...action,
      };
   }

   return { ...state };
};

/**
 * Replacing String With These Characters
 *
 * @param {*} str
 *
 */
export const decodeString = str => {
   return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#x27;/g, "'")
      .replace(/&#x2F;/g, "/");
};

/**
 * Prevent Background Scroll when a modal is open
 *
 */

export const preventScroll = () => {
   // When the modal is shown, we want a fixed body
   document.body.style.position = "fixed";
   document.body.style.top = `-${window.scrollY}px`;
};

/**
 * Enable Backgroundscroll after closing of the Modal
 *
 */

export const enableScroll = () => {
   // When the modal is hidden...
   const scrollY = document.body.style.top;
   document.body.style.position = "";
   document.body.style.top = "";
   window.scrollTo(0, parseInt(scrollY || "0") * -1);
};

/**
 * Display Date with / Example : 0/10/2020
 *
 * @param {*} date
 */
export const dateToDispFormat = date => {
   let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

   if (month.length < 2) month = "0" + month;
   if (day.length < 2) day = "0" + day;

   return [day, month, year].join("/");
};

// Copy to Clopboard
export const copyToClipboard = element => {
   var range = document.createRange();
   range.selectNode(document.getElementById(element));
   window.getSelection().removeAllRanges(); // clear current selection
   window.getSelection().addRange(range); // to select text
   document.execCommand("copy");
   window.getSelection().removeAllRanges(); // to deselect
};

// Same Height Div
export const sameHeightDiv = tag => {
   const allParagraph = document.querySelectorAll(tag);
   let maxHeight = 0;
   allParagraph.forEach(element => {
      if (element.clientHeight > maxHeight) {
         maxHeight = element.clientHeight;
      }
   });

   allParagraph.forEach(element => {
      element.style.height = maxHeight + "px";
   });
};

// Hide Popup On Background Click
export const hideModalOnBackground = (e, fn, className) => {
   if (e.target.classList.contains(className)) {
      return fn();
   }
};

// Disable Background Scroll on Popup
export const useLockBodyScroll = () => {
   useLayoutEffect(() => {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden";
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle);
   }, []);
};
/**
 *
 * Setting the OverLay Tool Tip to hide
 *
 * @param {*} key
 */
export const closeToolTipButton = key => {
   console.log('Running Local');
   localStorage.setItem(key, false);
};
