import { FETCH_POSTS } from "../actions/home/type";

const initialState = {
    items: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSTS:
            return {
                ...state,
                ...action
            };
        default:
            return state;
    }
};
