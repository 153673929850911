import { SHOW_BUTTON } from "../actions/scanbutton/type";

const initialState = {
   showButton: true,
};

export default (state = initialState, action) => {
   switch (action.type) {
      case SHOW_BUTTON:
         return {
            ...state,
            ...action,
         };
      default:
         return state;
   }
};
