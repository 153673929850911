import React, { useEffect } from "react";
import SkewImage from "../../../../public/images/skewImage.svg";
import Button from "../../../Button/Button";
import Description from "../../../Description/Description";
import MainText from "../../../MainText/MainText";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { fetchingActivity } from "../../../../actions/activity";
import FootCarousel from "../../../Carousels/FootCarousel/FootCarousel";
import CardCarousel from "../../../Carousels/CardCarousel/CardCarousel";

const ActivityDetials = (props) => {
  const items = useSelector((state) => get(state.activity, "items", []));

  const routeParam = props.match.params.id;

  const newCombinedArray = [
    ...get(items, "aquarium", []),
    ...get(items, "ds", []),
    ...get(items, "marine", []),
    ...get(items, "popular", []),
    ...get(items, "sealion", []),
  ];

  const filteredItems = newCombinedArray.map((data) => data).find((value) => value.uid === routeParam);

  const dispatch = useDispatch();

  const filteredFootCarouselItems = newCombinedArray.filter(
    (data) => data.arrayname === filteredItems.arrayname
  );

  /**
   * Triggers onLoad of the Page
   *
   */
  useEffect(() => {
    dispatch(fetchingActivity());
  }, [dispatch]);

  return (
    <div className="activity-detials-container">
      <div className="header-section">
        <Description className="description-18">{get(filteredItems, "name", "")}</Description>
        <img src={require("../../../../public/images/close.svg")} onClick={props.history.goBack} alt="" />
      </div>

      <div className="desktop-section-1">
        <div className="image-section">
          <img src={get(filteredItems, "image", "")} loading="lazy" alt="" />
        </div>

        <div className="section section-one">
          <Description className="description-18">{get(filteredItems, "name", "")}</Description>

          <Description className="description-14">{get(filteredItems, "desc", "")}</Description>

          <div className="list-group">
            <div className="list-item">
              <div className="items">
                <img src={require("../../../../public/images/ic_baby.svg")} alt="" />
              </div>
              <h1>
                Age Limit: <span style={{ textTransform: "lowercase" }}>{get(filteredItems, "age", "")}</span>{" "}
              </h1>
            </div>
            <div className="list-item">
              <div className="items">
                <img src={require("../../../../public/images/noun_Diving_986954.svg")} alt="" />
              </div>
              <h1>{get(filteredItems, "train", "")}</h1>
            </div>
            <div className="list-item">
              <div className="items">
                <img src={require("../../../../public/images/ic_calendar.svg")} alt="" />
              </div>
              <h1>Daily {/*{get(filteredItems, "time", "")} */}</h1>
            </div>
            <div className="list-item">
              <div className="items">
                <img src={require("../../../../public/images/ic_clock_time.svg")} alt="" />
              </div>
              <h1>Duration: {get(filteredItems, "duration", "")}</h1>
            </div>
            <div className="list-item">
              <div className="items">
                <img src={require("../../../../public/images/ic_price.svg")} alt="" />
              </div>
              <h1>Price: {get(filteredItems, "price", "")}</h1>
            </div>
            <div className="list-item">
              <div className="items">
                <img src={require("../../../../public/images/ic_people.svg")} alt="" />
              </div>
              <h1>{get(filteredItems, "type", "")}</h1>
            </div>
          </div>
          <Button
            onClick={() => window.open(get(filteredItems, "bookinglink", ""), "__target")}
            className="normal-button"
          >
            Book now
          </Button>
        </div>
      </div>

      <div className="section-two">
        <div className="text-section">
          <MainText>Package Includes</MainText>
        </div>
        <div className="skew-container" style={{ backgroundImage: "url(" + SkewImage + ")" }}>
          {get(filteredItems, "includes", []).map((data, index) => (
            <div className="container" key={index}>
              <div className="image">
                <img src={data.image !== false ? data.image : ""} alt="" />
              </div>
              <div className="summary">
                <p>{get(data, "text")}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="section-three">
        <div className="text-section">
          <MainText>You may be interested in</MainText>
        </div>
        <FootCarousel media={filteredFootCarouselItems} />
      </div>

      <div className="section section-four">
        <MainText>{get(filteredItems, "enrichments.textall.heading.english", "")}</MainText>

        <CardCarousel
          description={get(filteredItems, "enrichments.textall.description.english", "")}
          videos={get(filteredItems, "enrichments.medias", [])}
        />
      </div>

      {/* <div className="section-five">
            <div className="text-section">
               <MainText>{guestItems.heading.english}</MainText>
            </div>

            <SkewCarousel images={guestItems.pairs} />
         </div> */}
    </div>
  );
};
export default ActivityDetials;
