import React from "react";

/**
 * Generic Function
 *
 * Reusable for all the Components
 *
 */

export default props => {
    return (
        <div className="button-container">
            <button onClick={props.onClick} className={props.className} id={props.id} disabled={props.disabled}>
                {props.children}
                <img className="image pink" src={require("../../public/images/ic_arrow-right.svg")} alt="" />
                <img className="image white" src={require("../../public/images/ic_arrow-right-white.svg")} alt="" />
                <img className="image headphone" src={require("../../public/images/noun_Headphones.svg")} alt="" />
                <img className="image reach" src={require("../../public/images/reach.svg")} alt="" />
                <img className="image reach-blue" src={require("../../public/images/reach-blue.svg")} alt="" />
            </button>
        </div>
    );
};
