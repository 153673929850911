import { FETCH_POSTS } from "./type";
import { axiosCustom } from "../../utils/Request";

export const fetchingLostChambers = () => async (dispatch) => {
    const response = await axiosCustom.get("lost/v1/getlost");
    const { data = false, status } = response;
    if (status === 200) {
        if (data) {
            dispatch({
                type: FETCH_POSTS,
                items: data.acf,
            });
        }
    }
};
