import { FETCH_LINK } from "../actions/footer/type";

const initialState = {
   link: "",
};

export default (state = initialState, action) => {
   switch (action.type) {
      case FETCH_LINK:
         return {
            ...state,
            ...action,
         };
      default:
         return state;
   }
};
